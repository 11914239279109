/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import Text from '../Text';
import Button, { variants } from '../Button';
import RadioInput from '../RadioInput';
import TextArea from '../TextArea';
import classes from './styles.module.scss';
import { REASONS } from '../../constants';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { IFeedbackValue, OnRadioChange, RADIO_INPUT_NAME } from '../../containers/App';
import { useTranslation } from 'react-i18next';

interface IFeedbackFormProps {
    close: () => void;
    onSubmit: (event: React.FormEvent) => void;
    onChange: OnRadioChange;
    value: IFeedbackValue;
}

interface IOption {
    label: string;
    value: string;
}

const FeedbackForm: React.FC<IFeedbackFormProps> = ({ close, onSubmit, onChange, value }) => {
    const { t } = useTranslation('common');
    function getOptions(): Array<IOption> {
        return [
            {
                label: t('form.accuracyOption'),
                value: REASONS.ACCURACY,
            },
            {
                label: t('form.relevanceOption'),
                value: REASONS.RELEVANCE,
            },
            {
                label: t('form.otherOption'),
                value: REASONS.OTHER,
            },
        ];
    }

    return (
        <form className={classes.form} onSubmit={onSubmit} noValidate method=''>
            <div className={classes.header}>
                <Text className={classes.subtitle}>
                    {t('form.formSubtitlePart1')}
                    <br />
                    {t('form.formSubtitlePart2')}
                </Text>
            </div>
            <div className={classes.body}>
                <div>
                    {getOptions().map(option => (
                        <RadioInput
                            key={option.value}
                            {...option}
                            name={RADIO_INPUT_NAME}
                            onChange={onChange}
                            checked={value[RADIO_INPUT_NAME] === option.value}
                        />
                    ))}
                </div>
                {value[RADIO_INPUT_NAME] === REASONS.OTHER && (
                    <TextArea name='comment' onChange={onChange} placeholder={t('form.textAreaPlaceholder')} />
                )}
            </div>
            <div className={classes.footer}>
                <Button onClick={close} text={t('form.cancel')} variant={variants.default} />
                <Button onClick={() => {}} text={t('form.submit')} variant={variants.submit} />
            </div>
            <button onClick={close} className={classes.close} da-id='x-button'>
                <CloseIcon />
            </button>
        </form>
    );
};

export default FeedbackForm;
