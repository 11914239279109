import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { variants } from '../../components/Button';
import Portal from '../../components/Portal';
import ClickAwayListener from '../../components/ClickAwayListener';
import FeedbackForm from '../../components/FeedbackForm';
import classes from './styles.module.scss';
import { trimPayload, blockScroll, unblockScroll } from '../../utils';
import { REASONS } from '../../constants';
import { postFeedback } from './postFeedback';

export const RADIO_INPUT_NAME = 'selectedReasonKey';

const DEFAULT_FORM_VALUES = {
    [RADIO_INPUT_NAME]: REASONS.ACCURACY,
};

interface IAppProps {
    featureKey: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connectedInformation?: any;
}

export interface IOnRadioChangeArgs {
    name: string;
    value: string;
}

export interface IFeedbackValue {
    name?: string;
    [RADIO_INPUT_NAME]: string;
}

export interface IPayload {
    featureKey: string;
    useful: boolean;
    name?: string;
    [RADIO_INPUT_NAME]?: string;
}

export type OnRadioChange = ({ name, value }: IOnRadioChangeArgs) => void;

const App: React.FC<IAppProps> = ({ featureKey, connectedInformation }: IAppProps) => {
    const { t } = useTranslation('common');
    const [isVoted, setIsVoted] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [feedbackValue, setFeedbackValue] = useState<IFeedbackValue>(DEFAULT_FORM_VALUES);

    const openModal: (event: React.MouseEvent<HTMLElement>) => void = useCallback(e => {
        e.stopPropagation();
        blockScroll();
        setIsModalOpen(true);
    }, []);

    const closeModal: () => void = useCallback(() => {
        setIsModalOpen(false);
        setFeedbackValue(DEFAULT_FORM_VALUES);
        unblockScroll();
    }, []);

    const sendFeedback: (payload: IPayload) => void = useCallback(
        postFeedback({
            connectedInformation,
            onThen: () => {
                closeModal();
                setIsVoted(true);
            },
        }),
        [],
    );

    const onSubmit: (event: React.FormEvent) => void = useCallback(
        e => {
            e.preventDefault();
            sendFeedback({
                featureKey,
                useful: false,
                ...trimPayload(feedbackValue),
            });
        },
        [featureKey, feedbackValue, sendFeedback],
    );

    const sendPositiveFeedback: (event: React.MouseEvent<HTMLElement>) => void = useCallback(
        e => {
            e.stopPropagation();
            sendFeedback({ featureKey, useful: true });
        },
        [featureKey, sendFeedback],
    );

    const onRadioChange: OnRadioChange = useCallback(
        ({ value, name }) => {
            setFeedbackValue({ ...feedbackValue, [name]: value });
        },
        [feedbackValue],
    );

    return (
        <>
            <div className={classes.poll}>
                {!isVoted && (
                    <div className={classes.pollOptions}>
                        <Button onClick={openModal} text={t('card.notUseful')} variant={variants.cancel} />
                        <Button onClick={sendPositiveFeedback} text={t('card.useful')} variant={variants.submit} />
                    </div>
                )}
            </div>
            <Portal isOpen={isModalOpen}>
                <ClickAwayListener onClickAway={closeModal}>
                    <FeedbackForm
                        onSubmit={onSubmit}
                        onChange={onRadioChange}
                        value={feedbackValue}
                        close={closeModal}
                    />
                </ClickAwayListener>
            </Portal>
        </>
    );
};

export default App;
