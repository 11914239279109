import { initReactI18next } from 'react-i18next';
import i18n, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';

const initI18n = async (): Promise<TFunction> => i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        nonExplicitWhitelist: false,
        fallbackLng: 'en',
        debug: true,
        resources: { en: { common: en } },
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
            useSuspense: false,
        },
    } as any);

export default initI18n;
