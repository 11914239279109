import React from 'react';
import { OnRadioChange } from '../../containers/App';
import classes from './styles.module.scss';

interface ITextAreaProps {
    name: string;
    placeholder: string;
    onChange: OnRadioChange;
}

const TextArea: React.FC<ITextAreaProps> = ({ name, placeholder, onChange }) => {
    function onAreaChange(event: React.FormEvent<HTMLTextAreaElement>): void {
        onChange({
            name,
            value: event.currentTarget.value,
        });
    }

    return (
        <textarea
            maxLength={300}
            name={name}
            onChange={onAreaChange}
            className={classes.textarea}
            rows={3}
            placeholder={placeholder}
        />
    );
};

export default TextArea;
