import { callApi } from '../../utils';
import { API_URL, FEEDBACK_THROTTLE } from '../../constants';
import { throttle } from '../../throttle';

import { IPayload } from '.';

interface PostFeedbackConfig {
    onThen: () => unknown;
    connectedInformation: { [key: string]: unknown };
}

type PostFeedback = ({ onThen, connectedInformation }: PostFeedbackConfig) => (payload: IPayload) => void;

export const postFeedback: PostFeedback = ({ onThen, connectedInformation }) =>
    throttle((payload: IPayload) => {
        callApi(API_URL, 'POST', { ...payload, connectedInformation })
            .then(onThen)
            .catch(() => {
                // placeholder
            });
    }, FEEDBACK_THROTTLE);
