export const REASONS = {
    ACCURACY: 'ACCURACY',
    RELEVANCE: 'RELEVANCE',
    OTHER: 'OTHER',
};

export const API_URL = '/feature-feedback/v1/featureFeedbacks';

export const PORTAL_ID = 'ai-prediction-portal';

export const FEEDBACK_THROTTLE = 1000;
