import React from 'react';
import cx from 'classnames';
import classes from './styles.module.scss';

export const variants = {
    default: classes.buttonDefault,
    submit: classes.buttonSubmit,
    cancel: classes.buttonCancel,
};

interface IButtonProps {
    text: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    variant: string;
}

const Button: React.FC<IButtonProps> = ({ text, onClick, variant }: IButtonProps) => {
    return (
        <button
            className={cx(classes.button, {
                [variant]: !!variant,
            })}
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default Button;
