export default {
    card: {
        useful: 'Useful',
        notUseful: 'Not Useful',
    },
    form: {
        formSubtitlePart1: 'Please share the reason why it was not useful for',
        formSubtitlePart2: 'you so we can improve in the future.',
        accuracyOption: 'Accuracy',
        relevanceOption: 'Relevance',
        otherOption: 'Other',
        cancel: 'Cancel',
        submit: 'Submit',
        textAreaPlaceholder: 'Please specify',
    },
};
