// Material-UI ClickAwayListener implementation

import React, { forwardRef, useRef, useEffect, useCallback } from 'react';
import * as ReactDOM from 'react-dom';

function mapEventPropToEvent(eventProp) {
    return eventProp.substring(2).toLowerCase();
}

function ownerDocument(node) {
    return (node && node.ownerDocument) || document;
}

function setRef(ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
    }
}

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

function useEventCallback(fn) {
    const ref = React.useRef(fn);
    useEnhancedEffect(() => {
        ref.current = fn;
    });
    return React.useCallback((...args) => (0, ref.current)(...args), []);
}

function useForkRef(refA, refB) {
    return React.useMemo(() => {
        if (refA == null && refB == null) {
            return null;
        }
        return refValue => {
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [refA, refB]);
}

const ClickAwayListener = forwardRef(function ClickAwayListener(props, ref) {
    const { children, mouseEvent = 'onClick', touchEvent = 'onTouchEnd', onClickAway } = props;
    const movedRef = useRef(false);
    const nodeRef = useRef(null);
    const mountedRef = useRef(false);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const handleNodeRef = useForkRef(nodeRef, ref);

    const handleOwnRef = React.useCallback(
        instance => {
            // eslint-disable-next-line react/no-find-dom-node
            setRef(handleNodeRef, ReactDOM.findDOMNode(instance));
        },
        [handleNodeRef],
    );
    const handleRef = useForkRef(children.ref, handleOwnRef);

    const handleClickAway = useEventCallback(event => {
        // The handler doesn't take event.defaultPrevented into account:
        //
        // event.preventDefault() is meant to stop default behaviours like
        // clicking a checkbox to check it, hitting a button to submit a form,
        // and hitting left arrow to move the cursor in a text input etc.
        // Only special HTML elements have these default behaviors.

        // IE 11 support, which trigger the handleClickAway even after the unbind
        if (!mountedRef.current) {
            return;
        }

        // Do not act if user performed touchmove
        if (movedRef.current) {
            movedRef.current = false;
            return;
        }

        // The child might render null.
        if (!nodeRef.current) {
            return;
        }

        // Multi window support
        const doc = ownerDocument(nodeRef.current);

        if (
            doc.documentElement &&
            doc.documentElement.contains(event.target) &&
            !nodeRef.current.contains(event.target)
        ) {
            onClickAway(event);
        }
    });

    const handleTouchMove = useCallback(() => {
        movedRef.current = true;
    }, []);

    useEffect(() => {
        if (touchEvent !== false) {
            const mappedTouchEvent = mapEventPropToEvent(touchEvent);
            const doc = ownerDocument(nodeRef.current);

            doc.addEventListener(mappedTouchEvent, handleClickAway);
            doc.addEventListener('touchmove', handleTouchMove);

            return () => {
                doc.removeEventListener(mappedTouchEvent, handleClickAway);
                doc.removeEventListener('touchmove', handleTouchMove);
            };
        }

        return undefined;
    }, [handleClickAway, handleTouchMove, touchEvent]);

    useEffect(() => {
        if (mouseEvent !== false) {
            const mappedMouseEvent = mapEventPropToEvent(mouseEvent);
            const doc = ownerDocument(nodeRef.current);

            doc.addEventListener(mappedMouseEvent, handleClickAway);

            return () => {
                doc.removeEventListener(mappedMouseEvent, handleClickAway);
            };
        }

        return undefined;
    }, [handleClickAway, mouseEvent]);

    return React.cloneElement(children, { ref: handleRef });
});

export default ClickAwayListener;
