/* eslint-disable @typescript-eslint/no-explicit-any */
export const isString: (value: any) => boolean = value => typeof value === 'string';
export const isArray: (value: any) => boolean = value => Array.isArray(value);
export const isObject: (value: any) => boolean = value => typeof value === 'object' && value !== null;

export const trimValue: (value: any) => any = value => {
    if (isString(value)) {
        return value.trim();
    }

    if (isArray(value)) {
        return value.map(trimValue);
    }

    if (isObject(value)) {
        return trimPayload(value);
    }

    return value;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function trimPayload(payload: any): Record<string, unknown> {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    return Object.keys(payload).reduce((acc: Record<string, unknown>, key: string) => {
        acc[key] = trimValue(payload[key]);
        return acc;
    }, {});
}

function getCookie(cname: string): string {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}

const buildHeaders = (): HeadersInit => {
    const XSRF_TOKEN = getCookie('xsrf_token_cookie');
    const headers: HeadersInit = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    if (XSRF_TOKEN) {
        headers['X-XSRF-TOKEN'] = XSRF_TOKEN;
    }

    return headers;
};

interface ICustomError extends Error {
    status?: unknown;
}

export function callApi(
    endpoint: string,
    method: string,
    body?: Record<string, unknown>,
    headers: HeadersInit = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
    return fetch(endpoint, {
        method,
        body: JSON.stringify(body),
        headers: { ...buildHeaders(), ...headers },
    }).then(({ status }) => {
        if (status >= 200 && status < 300) {
            return { status };
        }

        const error: ICustomError = new Error();
        error.status = status;
        throw { error };
    });
}

export const blockScroll = (): void => {
    const { style } = document.body;
    style.overflowY = 'hidden';
};

export const unblockScroll = (): void => {
    const { style } = document.body;
    style.overflowY = 'scroll';
};
