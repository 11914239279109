import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { PORTAL_ID } from './constants';
import initI18n from './i18n';

const RETRY_CREATE_PORTAL = 1000;

function createPortal(): void {
    if (document.getElementById(PORTAL_ID)) {
        return;
    }

    if (!document.body) {
        setTimeout(createPortal, RETRY_CREATE_PORTAL);
        return;
    }

    const container = document.createElement('div');
    container.id = PORTAL_ID;
    container.className = 'ui-dialog';
    document.body.appendChild(container);
}

const init: Init = (container, featureKey, connectedInformation) => {
    destroy(container);
    try {
        initI18n();
        ReactDOM.render(
            <Suspense fallback=''>
                <App featureKey={featureKey} connectedInformation={connectedInformation} />
            </Suspense>
        ,
        container
        );
        return true;
    } catch (e) {
        console.error('AI USEFULNESS INIT: ', e);
        return false;
    }
};

const destroy: Destroy = container => {
    if (!container) {
        return false;
    }
    try {
        ReactDOM.unmountComponentAtNode(container);
        return true;
    } catch (e) {
        console.error('AI USEFULNESS DESTROY: ', e);
        return false;
    }
};

createPortal();

if (!window.TEG) {
    window.TEG = {};
}

if (!window.TEG.featureUsefullness) {
    window.TEG.featureUsefullness = { init, destroy };
}

if (process.env.NODE_ENV === 'development') {
    require('./styles.dev.scss');
    init(document.getElementById('root'), 'QUOTE_PRICE_PREDICTION');
}
