import React from 'react';
import cx from 'classnames';
import classes from './styles.module.scss';
import { OnRadioChange } from '../../containers/App';

interface IRadioInputProps {
    name: string;
    value: string;
    label: string;
    onChange: OnRadioChange;
    checked: boolean;
}

const RadioInput: React.FC<IRadioInputProps> = ({ name, value, label, onChange, checked }) => {
    function onClick(): void {
        onChange({
            name,
            value,
        });
    }

    return (
        <label htmlFor={value} className={cx(classes.label, { [classes.checked]: checked })} onClick={onClick}>
            {label}
            <div className={classes.indicator} />
        </label>
    );
};

export default RadioInput;
