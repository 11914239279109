import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import classes from './styles.module.scss';

import { PORTAL_ID } from '../../constants';

interface IPortalProps {
    children: React.ReactNode;
    isOpen: boolean;
}

const Portal: React.FC<IPortalProps> = ({ children, isOpen }) => {
    return isOpen
        ? createPortal(
            <div className={classes.portal}>{children}</div>,
            document.querySelector(`#${PORTAL_ID}`) as HTMLElement,
        )
        : null;
};

export default memo(Portal);
